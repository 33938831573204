export default {
  notFound: {
    pageNotFound: 'Stránka nenalezena',
    notFoundText:
      'Na této adrese bohužel nic nemáme. Zkuste to z hlavní strany.',
    gotoHomepage: 'Jděte na hlavní stranu',
  },
  faq: {
    forDownload: 'Ke stažení',
  },
  consent: {
    title: 'Je Vám více než 18 let?',
    confirm: 'Ano, je.',
    reject: 'Ne',
  },
  carousel: {
    moreInfo: 'Informace o pivě',
    previous: 'Předchozí',
    next: 'Následující',
  },
  news: {
    title: 'Novinky',
    moreOnInstagram: 'Více na Instagramu',
  },
  events: {
    title: 'Kalendář akcí',
    showAll: 'Zobrazit všechny akce',
    moreInfo: 'Více informací',
    moreInfoOnSocial: 'Více informací na sociálních sítích',
  },
  about: {
    videos: 'Videa',
    story: 'Příběh',
    awards: 'Ocenění',
    brewery: 'Pivovar',
    history: 'Historie',
    beerVolume: 'Výstav: <strong>{volume}</strong>',
  },
  merch: {
    title: 'Dárkové předměty',
  },
  product: {
    buy: 'Koupit',
    buySize: 'Koupit {size}',
  },
  cart: {
    empty: 'Váš košík je prázdný.',
    title: 'Košík',
    close: 'Zavřít',
    size: 'Velikost {size}',
    oneSize: 'Jedna velikost',
    removeItem: 'Odebrat',
    free: 'Zdarma',
    sendOrder: 'Odeslat objednávku',
    sendingOrder: 'Odesílám objednávku…',
    subtotal: 'Mezisoučet',
    shippingFee: 'Doprava',
    paymentMethod: 'Platba',
    total: 'Celkem',
  },
  orderForm: {
    optional: ' (nepovinné)',
    // First name
    firstName: 'Jméno',
    firstNameMin: 'Jméno je příliš krátké.',
    firstNameMax: 'Jméno je příliš dlouhé.',
    firstNameRequired: 'Vyplňte prosím Vaše jméno.',
    // Last name
    lastName: 'Příjmení',
    lastNameMin: 'Příjmení je příliš krátké.',
    lastNameMax: 'Příjmení je příliš dlouhé.',
    lastNameRequired: 'Vyplňte prosím Vaše příjmení.',
    // Address 1
    address1: 'Ulice a číslo popisné',
    address1Min: 'Příliš krátké.',
    address1Max: 'Příliš dlouhé.',
    address1Required: 'Vyplňte prosím Vaši ulici a číslo popisné.',
    // Address 2
    address2: 'Číslo bytu',
    // City
    city: 'Město',
    cityMin: 'Příliš krátké.',
    cityMax: 'Příliš dlouhé.',
    cityRequired: 'Vyplňte prosím Vaše město.',
    // ZIP
    postCode: 'PSČ',
    postCodeMin: 'Příliš krátké.',
    postCodeMax: 'Příliš dlouhé.',
    postCodeRequired: 'Vyplňte prosím Vaše PSČ.',
    // Country
    country: 'Země',
    countryRequired: 'Vyberte prosím Vaši zemi.',
    countries: {
      CZ: 'Česká republika',
      SK: 'Slovensko',
      AT: 'Rakousko',
      PL: 'Polsko',
      DE: 'Německo',
    },
    // E-mail
    email: 'E-mail',
    emailInvalid: 'Nesprávný formát e-mailu.',
    emailRequired: 'Vyplňte prosím Váš e-mail.',
    // Phone
    phone: 'Telefon',
    phoneMin: 'Příliš krátké.',
    phoneMax: 'Příliš dlouhé.',
    phoneRequired: 'Vyplňte prosím Váš telefon.',
    // Note
    note: 'Poznámka',
    notePlaceholder: 'Napište například jinou doručovací adresu…',
    // Accept terms
    acceptTerms:
      'Souhlasím s obchodními podmínkami a podmínkami ochrany soukromí.',
    acceptTermsRequired: 'Musíte souhlasit s podmínkami.',
    consent:
      'Odesláním souhlasíte s obchodními podmínkami a podmínkami ochrany soukromí.',
  },
  beers: {
    all: 'Všechny opice',
  },
  beer: {
    aboutBeer: 'O pivu',
    moreBeers: 'Další opice',
    backToAllBeers: 'Zpět na všechny Opice',
    info: {
      temperature: 'Teplota servírování: {temperature} °C',
      alcohol: 'Alkohol',
      extract: 'Extrakt',
      malt: 'Slad',
      bitterness: 'Hořkost',
      hop: 'Chmel',
      color: 'Barva',
    },
    nextStep: {
      title: 'Máte chuť na pivo? Podívejte se kam na něj můžete skočit.',
      label: 'Kam na pivo',
    },
  },
  locations: {
    ourBrewery: 'Náš pivovar',
    tap: 'Výčep',
    retail: 'Prodej piva',
    filter: {
      placeholder: 'Hledejte podle podniku nebo města…',
    },
  },
  offer: {
    openingHours: 'Otevírací doba',
    pricing: 'Ceník piva',
    excursions: 'Exkurze',
    tapRental: 'Půjčovna výčepů',
  },
  contact: {
    address: 'Adresa',
  },
  footer: {
    moreInfo: 'Více informací',
    socialNetworks: 'Sociální sítě',
    marketing: {
      title: 'Marketing',
      mediaKit: 'Media KIT ke stažení',
      images: 'Obrázky lahví, etikety, bannery',
    },
    copyright:
      'Pivovar Nachmelená Opice © {year}. Všechna práva vyhrazena. Tato stránka využívá cookies. Nastavení můžete změnit ve vašem prohlížeči.',
  },
}
