// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-beer-js": () => import("./../../../src/templates/beer.js" /* webpackChunkName: "component---src-templates-beer-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-kam-na-pivo-js": () => import("./../../../src/templates/kam-na-pivo.js" /* webpackChunkName: "component---src-templates-kam-na-pivo-js" */),
  "component---src-templates-kontakt-js": () => import("./../../../src/templates/kontakt.js" /* webpackChunkName: "component---src-templates-kontakt-js" */),
  "component---src-templates-nabizime-js": () => import("./../../../src/templates/nabizime.js" /* webpackChunkName: "component---src-templates-nabizime-js" */),
  "component---src-templates-nase-opice-js": () => import("./../../../src/templates/nase-opice.js" /* webpackChunkName: "component---src-templates-nase-opice-js" */),
  "component---src-templates-o-nas-js": () => import("./../../../src/templates/o-nas.js" /* webpackChunkName: "component---src-templates-o-nas-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-podpora-js": () => import("./../../../src/templates/podpora.js" /* webpackChunkName: "component---src-templates-podpora-js" */),
  "component---src-templates-udalosti-js": () => import("./../../../src/templates/udalosti.js" /* webpackChunkName: "component---src-templates-udalosti-js" */)
}

