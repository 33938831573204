/* eslint-disable import/prefer-default-export */
import React from 'react'
import { IntlProvider } from 'react-intl'

import cs from './src/locale/cs'
import flattenMessages from './src/utils/flattenMessages'

export const wrapRootElement = ({ element }) => {
  const locale = 'cs'
  const messages = flattenMessages(cs)

  return (
    <IntlProvider locale={locale} messages={messages}>
      {element}
    </IntlProvider>
  )
}
